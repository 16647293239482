//Function for capitalizing first letter
Object.defineProperty(String.prototype, "capitalize", {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});
Object.defineProperty(Array.prototype, "getPreview", {
  value: function () {
    if (this.length > 0) {
      const firstItem = this[0];
      if (firstItem instanceof Blob || firstItem instanceof File) {
        const blobUrl = URL.createObjectURL(firstItem);
        return blobUrl;
      }
    }
    return null; // Return null if the array is empty or the first item is not a Blob or File
  },
  enumerable: false,
});

Object.defineProperty(Object.prototype, "getOwnPropertyDescriptors", {
  value: function () {
    var obj = JSON.parse(JSON.stringify(this));
    Object.keys(obj).reduce(function (obj, key) {
      if (obj["_" + key] === obj[key]) {
        delete obj["_" + key];
      }
      return obj;
    }, obj);
    return obj;
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, "transformServiceSlug", {
  value: function () {
    const transformedString = this.replace(/([a-z]+)-(\w+)-(\w+)/, "$2 - $3");
    return transformedString;
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, "transformServiceSlugType", {
  value: function () {
    const transformedString = this.replace(/([a-z]+)-(\w+)-(\w+)/, "$2");
    return transformedString;
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, "formatFollowers", {
  value: function () {
    if (!isNaN(this)) {
      let subtitleValue = parseInt(this);

      if (subtitleValue >= 0 && subtitleValue <= 100) {
        return "0 - 99 Followers";
      } else if (subtitleValue >= 100 && subtitleValue <= 1000) {
        return "100 - 999 Followers";
      } else if (subtitleValue >= 1000 && subtitleValue <= 5000) {
        return "1000 - 4999 Followers";
      } else if (subtitleValue >= 5000 && subtitleValue <= 10000) {
        return "5000 - 9999 Followers";
      } else if (subtitleValue >= 10000 && subtitleValue <= 50000) {
        return "10000 - 49999 Followers";
      } else if (subtitleValue >= 50000 && subtitleValue <= 100000) {
        return "50000 - 99999 Followers";
      } else if (subtitleValue >= 100000 && subtitleValue <= 250000) {
        return "100000 - 249999 Followers";
      } else if (subtitleValue >= 250000 && subtitleValue <= 500000) {
        return "250000 - 499999 Followers";
      } else if (subtitleValue >= 500000 && subtitleValue <= 1000000) {
        return "500000 - 999999 Followers";
      } else if (subtitleValue >= 1000000 && subtitleValue <= 2000000) {
        return "1000000 - 1999999 Followers";
      } else {
        return "2000000+ Followers";
      }
    } else {
      return parseInt(this);
    }
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, "formatPlatform", {
  value: function () {
    switch (this.toLowerCase()) {
      case "tkt":
        return "TikTok";
      case "twt":
        return "Twitter/X";
      case "ig":
        return "Instagram";
      case "fb":
        return "Facebook";
      case "onsite":
        return "On location";
    }
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, "formatPlatformSlug", {
  value: function () {
    let val = this.toLowerCase();
    val = val.replace("tkt", "tiktok");
    val = val.replace("twt", "twitter");
    val = val.replace("ig", "instagram");
    val = val.replace("fb", "facebook");
    val = val.replace("onsite", "onsite");
    return val;
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, "formatFullPlatformSlug", {
  value: function () {
    let val = this.toLowerCase();
    val = val.replace("tiktok", "tkt");
    val = val.replace("twitter", "twt");
    val = val.replace("instagram", "ig");
    val = val.replace("facebook", "fb");
    val = val.replace("onsite", "onsite");
    return val;
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, "getFileType", {
  value: function () {
    let val = this.toLowerCase();

    let imageTypes = {
      gif: "image/gif",
      jpeg: "image/jpeg",
      jpe: "image/jpeg",
      jpg: "image/jpeg",
      png: "image/png",
    };
    let videoTypes = {
      mpeg: "video/mpeg",
      mpe: "video/mpeg",
      mpg: "video/mpeg",
      mp4: "video/mp4",
      m4v: "video/mp4",
      mov: "video/quicktime",
    };

    let audioTypes = { mp4: "audio/mpeg", wav: "audio/wav" };

    if (Object.keys(imageTypes).includes(val)) {
      return imageTypes[val];
    }
    if (Object.keys(videoTypes).includes(val)) {
      return videoTypes[val];
    }
    if (Object.keys(audioTypes).includes(val)) {
      return audioTypes[val];
    }
  },
  enumerable: false,
});

Object.defineProperty(Number.prototype, "timeAgo", {
  value: function () {
    let epochTime = this;

    if (Number.isNaN(epochTime)) {
      return `0s`;
    }

    // Calculate the difference in seconds between now and the epoch time
    const now = Math.floor(Date.now() / 1000);
    const diffSeconds = now - epochTime;

    // Calculate the time difference in minutes, hours, and days
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    // Format the time difference
    if (diffDays > 0) {
      const remainingHours = diffHours % 24;
      return `${diffDays}d ${remainingHours}h`;
    } else if (diffHours > 0) {
      return `${diffHours}h`;
    } else if (diffMinutes > 0) {
      return `${diffMinutes}m`;
    } else {
      return `${diffSeconds}s`;
    }
  },
  enumerable: false,
});

Object.defineProperty(Number.prototype, "convertUnixToDate", {
  value: function () {
    let timestamp = this;
    // Create a new Date object from the timestamp
    const date = new Date(timestamp * 1000);

    // Extract the day, month, and year from the Date object
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const year = date.getFullYear();

    // Format the date as dd/mm/yyyy
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  },
  enumerable: false,
});

// converts number to ordinal string. 1 = 1st, 2 = 2nd, 3 = 3rd, 4 = 4th, etc.
Object.defineProperty(Number.prototype, "getOrdinal", {
  value: function () {
    const number = this;

    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return number + "th";
    } else if (lastDigit === 1) {
        return number + "st";
    } else if (lastDigit === 2) {
        return number + "nd";
    } else if (lastDigit === 3) {
        return number + "rd";
    } else {
        return number + "th";
    }
  },
})
