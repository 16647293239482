// Styles
import 'vuetify/styles'
// import { VStepper } from 'vuetify/labs/VStepper'
// import {
//   VDataTable,
//   VDataTableServer,
//   VDataTableVirtual,
// } from "vuetify/labs/VDataTable";
// Vuetify
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { VCalendar } from 'vuetify/labs/VCalendar'


export default createVuetify({
    components: {
        VCalendar,
    },
    breakpoint: {
        scrollBarWidth: 16,
        thresholds: {
            xs: 576,
            sm: 768,
            md: 992,
            lg: 1200,
            xl: 1400,
        },
    },
    rtl: false,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                colors: {
                    primary: '#F24139',
                    secondary: '#403E48',
                    primary100: '#FEECEB',
                    bluecool200: '#D9D8DA',
                    bluecool600: '#403E48',
                    fireaxered100: '#FBFAFB',
                    fireaxered200: '#F6F5F7',
                    lightorange: '#FFDDA6',
                    warning: '#FB3',
                    success: '#00C851',
                    error: '#F44'
                },
                dark: false,
            },
        },
        defaultTheme: 'light',
    }
});