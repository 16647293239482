import api from "../services/api";

export default {
	async canGetUserLocation() {
		return api
			.get(`v1/users/settings/location`)
			.then((res) => {
				let setting = res.data.data;
				if (setting != undefined && setting != false) {
					if (setting.frequency == "always" || setting.frequency == "on_use") {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			})
			.catch(() => {
				return false;
			});
	},
	async getLocation() {
		const canUpdate = await this.canGetUserLocation();
		if (navigator.geolocation && canUpdate) {
			navigator.geolocation.getCurrentPosition(this.updateLocation, this.handleError);
		}
	},
	updateLocation(position) {
		api.post(`v1/users/me/location`, { long: position.coords.longitude, lat: position.coords.latitude })
			.then((res) => {
				if (res.data.success) {
					console.log(`Updated user location to Latitude: ${position.coords.latitude} and Longitude: ${position.coords.longitude}`);
				} else {
					console.log(`Failed updating userlocation to Latitude: ${position.coords.latitude} and Longitude: ${position.coords.longitude}`);
				}
			})
			.catch(() => {
				console.log(`Failed updating userlocation to Latitude: ${position.coords.latitude} and Longitude: ${position.coords.longitude}`);
			});
	},
	tryIPbasedUpdate(){
		api.post(`v1/application/me/iplocation`, {})
			.then((res) => {
				if (res.data.success) {
					console.log(`Updated user location to ip guessed location`);
				} else {
					console.log(`Failed updating userlocation ip guessed location`);
				}
			})
			.catch(() => {
				console.log(`Failed updating to ip guessed location.`);
			});
	},
	handleError(error) {
		switch (error.code) {
			case error.PERMISSION_DENIED:
				error = "User denied the request for Geolocation.";
				break;
			case error.POSITION_UNAVAILABLE:
				error = "Location information is unavailable.";
				break;
			case error.TIMEOUT:
				error = "The request to get user location timed out.";
				break;
			case error.UNKNOWN_ERROR:
				error = "An unknown error occurred.";
				break;
		}
		console.error(error);
	},
};
